import React, {useState, onChange } from "react";
import { Link } from "gatsby";
import { useForm, ValidationError } from "@formspree/react";
import { Button, Modal, Form } from "react-bootstrap";


function EmailGate() {
  const isBrowser = typeof window !== "undefined"
  function useLocalState(localItem){
    const[loc, setState] = useState(() => {
      if (isBrowser) { localStorage.getItem(localItem); }
      else { return true }
    });
    function setLoc(bool){
      if (isBrowser) { 
        localStorage.setItem(localItem, ""+bool)
        setState(""+bool);
      }
      else{ setState(bool); }
    }
    return [loc, setLoc]
  }  
  
  const [hasFilled, setFilled] = useLocalState("hasFilled");

  const [show, setShow] = useState(() =>{
      if(isBrowser && localStorage.getItem('hasFilled') == null){
          return true;
      }else if(isBrowser && localStorage.getItem('hasFilled') == 'true'){
        return false;
      }return true;

    });


  const [state, handleSubmitForm] = useForm("xbjwqywb") ;

  const ResponseMsg = () => {
    if (state.succeeded) {
      setShow(false)
      setFilled(true)
      return <p> </p>;
    } else {
      return <p> </p>;
    }
  };


    return (
      <>
      <Modal
        show={show}
        onHide={handleSubmitForm}
        backdrop="static"
        keyboard={false}
        size="lg"
         aria-labelledby="contained-modal-sizes-title-md"
         centered
      >
        <Modal.Header closeButton={false}>
         <Modal.Title id="contained-modal-sizes-title-md">We would love to connect!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="cta-form light-mode"
            onSubmit={handleSubmitForm}
            id="contact-form"
            action="https://formspree.io/f/xbjwqywb"
            method="POST"
            name="website-contact"
            data-netlify-honeypot="bot-field"
          >
            <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
              <label htmlFor="ctaFirstName">First Name </label>
              <input
                type="text"
                className="form-control gr-text-11"
                id="ctaFirstName"
                placeholder=""
                name="firstName"
                required
              />
              <ValidationError
                prefix="firstName"
                field="firstName"
                errors={state.errors}
              />
            </div>
            <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
              <label htmlFor="ctaLastName">Last Name </label>
              <input
                type="text"
                className="form-control gr-text-11"
                id="ctaLastName"
                placeholder=""
                name="lastName"
                required
              />
              <ValidationError
                prefix="lastName"
                field="lastName"
                errors={state.errors}
              />
            </div>
            <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
              <label htmlFor="ctaTitle">Title </label>
              <input
                type="text"
                className="form-control gr-text-11"
                id="ctaTitle"
                placeholder=""
                name="title"
              />
              <ValidationError
                prefix="title"
                field="title"
                errors={state.errors}
              />
            </div>
            <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
              <label htmlFor="ctaCompany">Pixeled Domain URL </label>
              <input
                type="text"
                className="form-control gr-text-11"
                id="ctaCompany"
                placeholder=""
                name="company"
              />
              <ValidationError
                prefix="company"
                field="company"
                errors={state.errors}
              />
            </div>
            <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
              <label htmlFor="ctaEmail">Email </label>
              <input
                type="email"
                className="form-control gr-text-11"
                id="ctaEmail"
                placeholder=""
                required=""
                name="_replyto"
                required
              />
              <ValidationError
                prefix="Email"
                field="_replyto"
                errors={state.errors}
              />
            </div>
            <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
              <label htmlFor="ctaPhone">Telephone </label>
              <input
                type="text"
                className="form-control gr-text-11"
                id="ctaPhone"
                placeholder=""
              />
              <ValidationError
                prefix="Phone"
                field="phone"
                errors={state.errors}
              />
            </div>
              <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
              <input
                name="SiteVisited"
                type="hidden"
                className="form-control gr-text-11"
                id="ctaPhone"
                value="Blog"
              />
            </div>
            <Button type="submit" className="rounded-8 my-4">
              Submit
            </Button>
            <div className="form-group my-4 gr-text-8 text-dark">
            </div>
              <ResponseMsg />
          </form>
        </Modal.Body>
      </Modal>
      {/* {showSuccess && <div class='success-message'>Success! Thank you for registering</div>} */}

    </>   
    );
};
export default EmailGate;